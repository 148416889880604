"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@popperjs/core");
var IFRAME_URL = process.env.HN_IFRAME_URL;
var ChangelogWidget = /** @class */ (function () {
    function ChangelogWidget(_config) {
        this.config = _config;
        this.iframeState = false;
        this.run();
    }
    ChangelogWidget.prototype.run = function () {
        if (this.config.selector) {
            this.createIframe();
        }
        else {
            console.log("Please provide a selector.");
        }
    };
    ChangelogWidget.prototype.setupListeners = function () {
        if (this.config.selector && document.querySelector(this.config.selector)) {
            document.addEventListener("click", this.toggleIframe.bind(this, this.config.selector));
        }
        window.addEventListener("message", this.handleNewMessage.bind(this));
    };
    ChangelogWidget.prototype.createIframe = function () {
        this.iframe = document.createElement("iframe");
        this.iframeId = "hn-changelog-widget";
        this.iframe.setAttribute("title", this.iframeId);
        this.iframe.setAttribute("src", IFRAME_URL);
        this.iframe.setAttribute("referrerPolicy", "origin");
        this.iframe.setAttribute("sandbox", "allow-scripts allow-forms allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-top-navigation allow-top-navigation-by-user-activation allow-modals");
        var popperElem = document.createElement("div");
        popperElem.setAttribute("id", this.iframeId);
        popperElem.setAttribute("class", "hn-changelog-wrapper");
        var styleTag = document.createElement("style");
        styleTag.innerHTML =
            ".hn-changelog-wrapper {z-index:2147483647;}#hn-changelog-widget>iframe{border:none;z-index:2147483647;height:0;width:0;max-height:700px;max-width:100vw;display:block!important;visibility:visible;background:none transparent;opacity:1;pointer-events:auto;touch-action:auto;background-color:#fff;border-radius:0.375rem;box-shadow:rgba(0, 0, 0, 0.15) 0px 2px 10px;}.hn-changelog-widget-iframe-show{height:450px!important;width:350px!important}#hn-changelog-indicator{height: 8px;width: 8px;border-radius: 99px;background-color:#ff1717e3;display:inline-flex;margin-left:5px;}.hn-changelog-trigger>*{ pointer-events:none;}";
        popperElem.append(styleTag);
        popperElem.append(this.iframe);
        var widgetElem = document.querySelector(this.config.selector);
        if (!widgetElem) {
            console.log("Not a valid selector.");
            return;
        }
        widgetElem.classList.add("hn-changelog-trigger");
        this.popper = core_1.createPopper(widgetElem, popperElem, {
            placement: this.config.placement || "auto",
            modifiers: [
                {
                    name: "offset",
                    options: { offset: [0, 10] },
                },
            ],
        });
        document.body.appendChild(popperElem);
        this.setupListeners();
    };
    ChangelogWidget.prototype.openIframe = function () {
        this.iframe.classList.add("hn-changelog-widget-iframe-show");
    };
    ChangelogWidget.prototype.closeIframe = function (iframeElement) {
        if (iframeElement) {
            iframeElement.classList.remove("hn-changelog-widget-iframe-show");
        }
        else {
            this.iframe.classList.remove("hn-changelog-widget-iframe-show");
        }
    };
    ChangelogWidget.prototype.toggleIframe = function (selector, e) {
        var matchedElements = document.querySelectorAll(selector);
        var isMatched = false;
        for (var i = 0; i < matchedElements.length; i++) {
            var matchedElement = matchedElements[i];
            if (e.target === matchedElements[i] ||
                matchedElement.contains(e.target)) {
                isMatched = true;
                break;
            }
        }
        if (!isMatched)
            return;
        e.preventDefault();
        if (this.iframeState) {
            this.closeIframe();
            this.iframeState = false;
        }
        else {
            this.openIframe();
            this.iframeState = true;
        }
        this.popper.update();
    };
    ChangelogWidget.prototype.handleIndication = function (indication) {
        var indicatorElem = document.getElementById("hn-changelog-indicator");
        if (indication) {
            if (!indicatorElem && this.config.enableIndicator) {
                indicatorElem = document.createElement("div");
                indicatorElem.setAttribute("id", "hn-changelog-indicator");
                document.querySelector(this.config.selector).append(indicatorElem);
            }
        }
        else {
            if (indicatorElem)
                indicatorElem.remove();
        }
    };
    ChangelogWidget.prototype.handleNewMessage = function (e) {
        e.preventDefault();
        if (!e.data || typeof e.data !== "string")
            return;
        try {
            var data = JSON.parse(e.data);
            switch (data.action) {
                case "init":
                    this.iframe.contentWindow.postMessage(JSON.stringify(this.config), "*");
                    break;
                case "indicate":
                    this.handleIndication(data.payload);
                    break;
                default:
                    return;
            }
        }
        catch (err) {
            return;
        }
    };
    return ChangelogWidget;
}());
// tslint:disable-next-line: no-empty
var HNChangelogWidget = function () { };
HNChangelogWidget.init = function (config) {
    if (!config || !config.selector || !config.token)
        return;
    if (!document.getElementById("hn-changelog-widget")) {
        var changelogWidget_1 = new ChangelogWidget(config);
        document.addEventListener("click", function (event) {
            if (event.target !==
                document.querySelector(changelogWidget_1.config.selector) &&
                event.target !== document.getElementById("hn-changelog-widget")) {
                changelogWidget_1.closeIframe();
                changelogWidget_1.iframeState = false;
            }
        });
        return changelogWidget_1;
    }
};
exports.default = HNChangelogWidget;
